import './App.css'
import React, { useState } from 'react'
import DocNavigation from './components/DocNavigation'
import DocRedoc from './components/DocRedoc'
import { ApiDocsContextProvider } from './context/docContext'

let initialized = false

const initContext = (
  setConfig: React.Dispatch<ApiDocs.IConfig>,
  setActiveApi: React.Dispatch<ApiDocs.IApiConfig>,
  setActiveSpecs: React.Dispatch<any>
) => {
  fetch('/api/config')
    .then((resp) => resp.json())
    .then((cfg: ApiDocs.IConfig) => {
      initialized = true
      setConfig(cfg)
      setActiveApi(cfg.apis[0])
      if (cfg.apis[0] && cfg.apis[0].specUrl) {
        fetch(cfg.apis[0].specUrl)
          .then((resp) => resp.json())
          .then((_specs) => {
            setActiveSpecs(_specs)
          })
      }
    })
}

function App() {
  const [config, setConfig] = useState<ApiDocs.IConfig>()
  const [activeApi, setActiveApi] = useState<ApiDocs.IApiConfig>()
  const [activeSpecs, setActiveSpecs] = useState<any>()
  if (!initialized) {
    initContext(setConfig, setActiveApi, setActiveSpecs)
  }

  const providerValue: IContextProvider = {
    config: config,
    activeApi: activeApi,
    activeSpecs: activeSpecs,
    setActiveSpecs: (_specs: any) => setActiveSpecs(_specs),
    setActiveApi: (_cfg: ApiDocs.IApiConfig) => setActiveApi(_cfg),
  }

  return (
    <ApiDocsContextProvider value={providerValue}>
      <DocNavigation id='main-nav'></DocNavigation>
      <DocRedoc></DocRedoc>
    </ApiDocsContextProvider>
  )
}

export default App
