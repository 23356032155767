import { useContext } from 'react'
import { RedocRawOptions, RedocStandalone } from 'stw-redoc'
import ApiDocsContext from '../context/docContext'
import { getToken } from '../helper/token'

const DocRedoc = () => {
  const docContext = useContext(ApiDocsContext)
  if (!docContext.activeSpecs) return <div />

  const redocOptions: RedocRawOptions = {
    requiredPropsFirst: true,
    hideDownloadButton: true,
    hideHostname: true,
    scrollYOffset: '#main-nav',
    swaggerOptions: {
      apiHostServers: [
        {
          url: docContext.activeApi?.apiUrl || '',
          variables: {},
        },
      ],
      onComplete: async function (_swaggerUi: any) {
        /**
         * if an authorization token has been configured, we can use it for auto-authentication
         */
        if (docContext.activeApi?.key) {
          let token = await getToken(docContext.activeApi?.key)
          _swaggerUi.preauthorizeApiKey('JWTAuth', token)
        }
      },
    },
  }

  return <RedocStandalone spec={docContext.activeSpecs} options={redocOptions}></RedocStandalone>
}

export default DocRedoc
