import { useContext, useState } from 'react'
import { Dropdown, DropdownButton, Nav, Navbar } from 'react-bootstrap'
import ApiDocsContext from '../context/docContext'

function DocNavigation(props: any) {
  const docContext = useContext(ApiDocsContext)
  const [version, setVersion] = useState<string>()

  fetch('/version')
    .then((resp) => resp.text())
    .then((ver) => setVersion(ver))

  return (
    <Navbar bg='light' {...props} sticky='top'>
      <Nav className='mr-auto'>
        <DropdownButton
          variant='light'
          id='dropdown-basic-button'
          title={`${docContext.activeSpecs?.info?.title || ''} ${
            docContext.activeSpecs?.info?.version || ''
          }`}
        >
          {NavItems(docContext.config?.apis || [])}
        </DropdownButton>
      </Nav>
      <Navbar.Collapse className='justify-content-end'>
        <Navbar.Text>huGO Docs version: {version}</Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  )
}

function NavItems(apis: ApiDocs.IApiConfig[]) {
  const docContext = useContext(ApiDocsContext)

  return apis.map((_cfg: ApiDocs.IApiConfig) => {
    return (
      <Dropdown.Item
        as='span'
        id={_cfg.key}
        key={_cfg.key}
        onClick={(e) => {
          docContext.setActiveApi(Object.assign({}, _cfg))

          if (_cfg?.specUrl) {
            fetch(_cfg.specUrl)
              .then((resp) => resp.json())
              .then((_specs) => {
                docContext.setActiveSpecs(Object.assign({}, _specs))
              })
          }
        }}
      >
        {_cfg.key}
      </Dropdown.Item>
    )
  })
}

export default DocNavigation
