import React from 'react'

let initialConfig: IContextProvider = {
  config: undefined,
  activeApi: undefined,
  activeSpecs: undefined,
  setActiveApi: () => {},
  setActiveSpecs: () => {},
}

const ApiDocsContext = React.createContext(initialConfig)

export const ApiDocsContextProvider = ApiDocsContext.Provider

export default ApiDocsContext
